import React, { useState, useEffect } from 'react';
import {
  CssBaseline,
  Divider,
  Box,
} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import MewtopiaTheme from './theme';
import AppAppBar from './components/Header';
import Footer from './components/Footer';
import SectionSplash from './splash';
import SectionFeatures from './features';
import SectionGameFi from './gamefi';
import SectionRoadMap from './roadmap';
import SectionPartner from './partner';
import SectionTeam from './team';

import './LandingPage.css';

const DISABLE_PRELOAD = true;

const getBackgroundImages = () => {
  const elements = document.querySelectorAll('*');
  // @ts-ignore
  const urls = [];
  elements.forEach(element => {
    const style = getComputedStyle(element);
    const backgroundImage = style.backgroundImage;
    if (backgroundImage && backgroundImage !== 'none') {
      try {
        // @ts-ignore
        const url = backgroundImage.match(/url\((['"])?(.*?)\1\)/)[2];
        if (url) {
          urls.push(url);
        }
      } catch (e) { }
    }
  });
  // @ts-ignore
  return urls;
};

const preloadImage = (src: any) => {
  console.log(`preloadImage`, src);
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = resolve;
    img.onerror = reject;
    img.src = src;
  })
};

export default function LandingPage() {
  const theme = createTheme(MewtopiaTheme('light'));
  const [isLoaded, setIsLoaded] = useState(DISABLE_PRELOAD);

  useEffect(() => {
    const backgroundImages = getBackgroundImages();
    Promise.all(backgroundImages.map(preloadImage))
      .then(() => setIsLoaded(true))
      .catch(err => {
        console.error('Failed to load one or more images', err);
        setIsLoaded(false);
      });
  }, []);

  return (<>
    <Box className="pre-loader"
      sx={{
        overflow: `hidden`,
        ...isLoaded ? {
          display: `none`,
          opacity: 0,
        } : {}
      }}>
      <Box className="pre-loader-icon" />
    </Box>

    <Box sx={{
      ...isLoaded ? {
        opacity: 1,
      } : {
        opacity: 0,
      }
    }}>
      <ThemeProvider
        theme={theme}
      >
        <CssBaseline />
        <AppAppBar />
        <SectionSplash />
        <SectionFeatures />
        <SectionGameFi />
        <SectionRoadMap />
        <SectionPartner />
        <SectionTeam />
        <Divider />
        <Footer />
        {/*
      <Box sx={{ bgcolor: 'background.default' }}>
        <Divider />
        <Footer />
      </Box>
      */}
      </ThemeProvider>
    </Box>
  </>);
}
