import * as React from 'react';
import { alpha, styled } from '@mui/material';
import {
  Box,
  Button,
  IconButton,
  Container,
  Link,
  Grid,
  Typography,
} from '@mui/material';
import HeaderWithRibbon from '../components/HeaderWithRibbon';

import IMGCard1 from './images/card1.png';
import IMGCard2 from './images/card2.png';
import IMGCard3 from './images/card3.png';

import IMGCardBG1 from './images/card1-bg.png';
import IMGCardBG2 from './images/card2-bg.png';

import IMGPlant from './images/plant.png';
import IMGMinion from './images/minion.png';

import './index.css';

const MyCardItem = ({ title, text, image, background }: {
  title?: string,
  text?: string,
  image?: string,
  background?: string,
}) => {
  return (<>
    <Box className="gamefi-card"
      sx={{
        height: {
          xs: `28em`,
          sm: `20em`,
          md: `20em`,
          lg: `25em`,
        }
      }}>

      <Box className="gamefi-card-content">

        <Box className="gamefi-card-background">
          <Box
            src={`${background}`}
            component='img'
          />
        </Box>

        <Box
          className="gamefi-card-header-title"
          component="h3">
          {title}
        </Box>

        <Box className="gamefi-card-image">
          <img src={`${image}`} />
        </Box>

        <Box className="gamefi-card-header-description" component="h5">
          {text}
        </Box>
      </Box>

      <Box className="gamefi-card-plant">
        <img src={`${IMGPlant}`} />
        <img src={`${IMGPlant}`} />
      </Box>
    </Box>
  </>);
}

export default function SectionGameFi() {
  return (
    <>
      <Box
        className="section-alt"
        id="gamefi"
        sx={(theme) => ({})}
      >
        <Container
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <HeaderWithRibbon text={'GameFi'} />

          <Grid
            container
            spacing={1}
            sx={{
              alignContent: 'center',
              alignItems: 'center',
              pb: 15
            }}>

            <Grid item lg={4} md={4} sm={6} xs={12}>
              <MyCardItem
                title='NO INFLATION'
                text='Fair-launched game token'
                image={IMGCard1}
                background={IMGCardBG1}
              />
            </Grid>

            <Grid item lg={4} md={4} sm={6} xs={12}>
              <MyCardItem
                title='GAME-FI EXPERIENCE'
                text='Obtain token from playing'
                image={IMGCard2}
                background={IMGCardBG2}
              />
            </Grid>

            <Grid item lg={4} md={4} sm={6} xs={12}>
              <MyCardItem
                title='HERO COLLECTION'
                text='Your favorite heroes is here to play'
                image={IMGCard3}
              />
            </Grid>
          </Grid>
        </Container>

        <Box
          component="img"
          src={`${IMGMinion}`}
          sx={{
            position: 'absolute',
            left: `0px`,
            top: {
              xs: '-100px',
              sm: '-150px',
              md: '-150px',
              lg: '-150px',
            },
            width: {
              xs: `35vw`,
              sm: `35vw`,
              md: `50vw`,
              lg: `50vw`,
            },
            maxWidth: `250px`,
            zIndex: `999`,
          }} />
      </Box>
    </>
  );
}