import * as React from 'react';
import { alpha, styled } from '@mui/material';
import {
  Box,
  Button,
  IconButton,
  Container,
  Link,
  Stack,
} from '@mui/material';
import HeaderWithRibbon from '../components/HeaderWithRibbon';

import Decor1 from './mee.png';

export default function SectionPartner() {
  return (
    <>
      <Box
        className="section-alt"
        id="partners"
        sx={(theme) => ({
        })}
      >
        <Container
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: { xs: 3, sm: 6 },
          }}
        >
          <HeaderWithRibbon text={'Partners'} />
        </Container>

        <Box
          component="img"
          src={`${Decor1}`}
          sx={{
            position: 'absolute',
            left: `0px`,
            top: {
              xs: '-100px',
              sm: '-150px',
              md: '-150px',
              lg: '-150px',
            },
            width: {
              xs: `25vw`,
              sm: `25vw`,
              md: `30vw`,
              lg: `30vw`,
            },
            maxWidth: `180px`,
            zIndex: `999`,
          }} />
      </Box>
    </>
  );
}