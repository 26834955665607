import * as React from 'react';
import { alpha, styled } from '@mui/material';
import {
  Box,
  Button,
  IconButton,
  Container,
  Link,
  Stack,
} from '@mui/material';
import { Discord as DiscordIcon, Twitter as TwitterIcon, Telegram as TelegramIcon, Controller as ControllerIcon } from 'react-bootstrap-icons';

import './index.css';

import IMGBackground from './assets/background.png';
import IMGButtonPlay from './assets/play.png';

import IMGMiao from './assets/miao.png';
import IMGQuin from './assets/quin.png';
import IMGRose from './assets/rose.png';
import IMGSilva from './assets/silva.png';

let logoStyles = {
  zIndex: 1000,
};

let decorStyles = {
  position: 'absolute',
  zIndex: 999,
};

export default function SectionSplash() {
  return (
    <>
      <Box
        id="splash"
        sx={(theme) => ({
          width: '100%',
          background: `url('${IMGBackground}') no-repeat center center`,
          backgroundSize: 'cover',

          position: 'relative',
        })}
      >
        <Box
          sx={(theme) => ({
            m: '0',
            p: '0',
            width: '100%',
            height: '100%',
            position: 'relative',
            overflowX: 'clip',
            boxShadow:
              theme.palette.mode === 'light'
                ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
          })}
        >
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              pt: { xs: 14, sm: 20 },
              pb: { xs: 8, sm: 12 },
            }}
          >
            <Stack
              direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'column' }}
              alignSelf="center"
              spacing={1}
              useFlexGap
              sx={{
                ...logoStyles,
                position: 'relative',
                pt: 2,
                pb: 2,
                width: { xs: '100%', sm: 'auto' },
                transform: {
                  xs: 'scale(0.7)',
                  md: 'scale(1.0)',
                }
              }}
            >
              <Box
                sx={(theme) => ({
                  alignSelf: 'center',
                  borderRadius: '20px',
                  outline: 'none',
                  width: { xs: '300px' }
                })}
              >
                <img src="/static/mewtopia/logo-512x512.png" width='100%' style={{
                  'borderRadius': '20px',
                  'border': `none`,
                  'animation': `rebounds_animation 2s ease-in-out infinite`,
                  userSelect: 'none',
                  pointerEvents: 'none'
                }} />
              </Box>

              <IconButton
                className="button-play"
                color="secondary"
                href='https://t.me/#'
                target='_blank'
                component="a"
                sx={{
                  mt: -5
                }}
              >
                <img src={IMGButtonPlay} width='70%' style={{
                  userSelect: 'none',
                  pointerEvents: 'none'
                }} />
              </IconButton>
            </Stack>

            {/* miao */}
            <Box
              className='anim-miao'
              sx={{
                ...decorStyles,
                top: {
                  xs: '350px',
                  sm: '350px',
                  md: '115px',
                },
                right: {
                  xs: '-130px',
                  sm: '-230px',
                  md: '-400px',
                },
                width: '100%',
                height: {
                  xs: '50%',
                  sm: '50%',
                  md: '100%',
                },
              }}>
              <Box
                component="img"
                src={IMGMiao}
                sx={{
                  // position: 'absolute',
                  // top: '115px',
                  // right: ' -400px',
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  userSelect: 'none',
                  pointerEvents: 'none'
                }} />
            </Box>

            {/* silva */}
            <Box
              className='anim-silva'
              sx={{
                ...decorStyles,
                top: {
                  xs: '80px',
                  sm: '80px',
                  md: '130px',
                },
                left: {
                  xs: '-100px',
                  sm: '-180px',
                  md: '-200px',
                },
                width: '100%',
                height: {
                  xs: '160px',
                  sm: '160px',
                  md: '160px',
                },
                overflow: 'clip'
              }}>
              <Box
                component="img"
                src={IMGSilva}
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  userSelect: 'none',
                  pointerEvents: 'none'
                }} />
            </Box>

            {/* Quin */}
            <Box
              className='anim-quin'
              sx={{
                ...decorStyles,
                top: {
                  xs: '380px',
                  sm: '380px',
                  md: '130px',
                },
                left: {
                  xs: '-130px',
                  sm: '-230px',
                  md: '-360px',
                },
                width: '100%',
                height: {
                  xs: '280px',
                  sm: '280px',
                  md: '600px',
                },
                overflow: 'clip'
              }}>
              <Box
                component="img"
                src={IMGQuin}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  userSelect: 'none',
                  pointerEvents: 'none'
                }} />
            </Box>

            {/* Rose */}
            <Box
              className='anim-rose'
              sx={{
                ...decorStyles,
                top: {
                  xs: '500px',
                  sm: '230px',
                  md: '130px',
                },
                left: {
                  xs: '-80px',
                  sm: '-230px',
                  md: '-400px',
                },
                width: '100%',
                height: {
                  xs: '150px',
                  sm: '150px',
                  md: '280px',
                },
                overflow: 'clip'
              }}>
              <Box
                component="img"
                src={IMGRose}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  userSelect: 'none',
                  pointerEvents: 'none'
                }} />
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
}
