import {
  Box,
  IconButton,
  Typography,
  Stack,
} from '@mui/material';

import {
  Discord,
  Twitter,
  Telegram,
  // Instagram,
  // Facebook,
  // Linkedin,
} from 'react-bootstrap-icons';

/*
function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {'Copyright © '}
      <Link href="https://mui.com/">Sitemark&nbsp;</Link>
      {new Date().getFullYear()}
    </Typography>
  );
}
*/

export default function Footer() {
  return (
    <Box
      className="section-main"
      id="footer"
      sx={{
        position: 'relative',
        width: '100%',
        height: '100px',
        minHeight: `auto !important`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 2, sm: 2 },
        py: { xs: 2, sm: 2 },
        textAlign: { sm: 'center', md: 'left' },
        color: `#FFF`,
        background: `#CF8457`
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Stack
          direction="row"
          justifyContent="left"
          alignItems="center"
          spacing={1}
          useFlexGap
          sx={{
            color: 'text.secondary',
          }}
        >
          <Typography sx={{ mx: 0.5, opacity: 0.5 }}>
            Follow us:
          </Typography>
          <IconButton
            target='_blank'
            color="inherit"
            href="https://t.me/MemeTDgame"
            aria-label="Telegram"
            sx={{ alignSelf: 'center' }}
          >
            <Telegram />
          </IconButton>
          <IconButton
            target='_blank'
            color="inherit"
            href="https://twitter.com/MemeTD_official"
            aria-label="X"
            sx={{ alignSelf: 'center' }}
          >
            <Twitter />
          </IconButton>

          <IconButton
            target='_blank'
            color="inherit"
            href="https://discord.gg/eaym8DQg99"
            aria-label="X"
            sx={{ alignSelf: 'center' }}
          >
            <Discord />
          </IconButton>

          {/*
          <IconButton
            target='_blank'
            color="inherit"
            href="https://discord.com/memetd"
            aria-label="X"
            sx={{ alignSelf: 'center' }}
          >
            <Instagram />
          </IconButton>
          
          <IconButton
            target='_blank'
            color="inherit"
            href="https://discord.com/memetd"
            aria-label="X"
            sx={{ alignSelf: 'center' }}
          >
            <Facebook />
          </IconButton>
          
          <IconButton
            target='_blank'
            color="inherit"
            href="https://discord.com/memetd"
            aria-label="X"
            sx={{ alignSelf: 'center' }}
          >
            <Linkedin /> 
          </IconButton>
          */}
        </Stack>
      </Box>
    </Box>
  );
}
