import * as React from 'react';
import { alpha, styled, Typography } from '@mui/material';
import {
  Box,
  Container,
  Grid,
} from '@mui/material';
import HeaderWithRibbon from '../components/HeaderWithRibbon';

import IMGYellowRibbon from './images/ribbon.png';

import Decor1 from './images/decor1.png';
import Decor2 from './images/decor2.png';

import IMGA1 from './images/a1.png';
import IMGA2 from './images/a2.png';
import IMGA3 from './images/a3.png';
import IMGA4 from './images/a4.png';

import './index.css';

const MyCardItem = ({ title, name, image }: {
  title?: string,
  name?: string,
  image?: string,
}) => {
  return (<>
    <Box className="team-card"
      sx={{
      }}>

      {/* avatar */}
      <Box className="team-card-avatar">
        <img src={image} />
      </Box>

      <Box className="team-card-name">
        <YellowWithRibbon text={name} />
      </Box>

      {/* title */}
      <Box className="team-card-title"
        sx={{
          display: `inline-flex`,
          justifyContent: `center`,
          width: `100%`
        }}>
        <Typography sx={{
          whiteSpace: 'nowrap',
          fontWeight: '600',
          color: '#333333',
          fontSize: {
            xs: `0.8em`,
            sm: `0.8em`,
            md: `0.8em`,
            lg: `0.8em`,
          },
          padding: {
            xs: `0em 2em 2em 2em`,
            sm: `0em 2em 2em 2em`,
            md: `0em 2em 2em 2em`,
            lg: `0em 2em 2em 2em`
          }
        }}
        >
          {title ?? ''}
        </Typography>
      </Box>
    </Box>
  </>);
}

const YellowWithRibbon = (props: {
  text?: string
}) => {
  return (
    <Box sx={{
      position: 'relative',
      maxWidth: `100%`
    }}>
      <Box
        sx={{
          background: `url(${IMGYellowRibbon}) no-repeat center center`,
          backgroundSize: `contain`,
          display: `inline-flex`,
          justifyContent: `center`,
          width: `100%`
        }}
      >
        <Typography sx={{
          whiteSpace: 'nowrap',
          fontWeight: '600',
          color: '#333333',
          fontSize: {
            xs: `0.9em`,
            sm: `0.9em`,
            md: `0.9em`,
            lg: `0.9em`,
          },
          padding: {
            xs: `1em 2em 2em 2em`,
            sm: `1em 2em 2em 2em`,
            md: `1em 2em 2em 2em`,
            lg: `1em 2em 2em 2em`
          }
        }}
        >
          {props.text ?? ''}
        </Typography>
      </Box>
    </Box >
  );
};

export default function SectionTeam() {
  return (
    <>
      <Box
        className="section-main"
        id="team"
        sx={(theme) => ({})}
      >
        <Container
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <HeaderWithRibbon text={'Team'} />

          <Typography
            variant='h5'
            sx={{
              textAlign: `center`,
              mt: 4,
              mb: 4,
              maxWidth: {
                xs: `80vw`,
                md: `60vw`,
              },
            }}
          >
            We are proud to be a dedicated team whose each member masters in his/her expertise and always enthusiastic to contribute developing Meow Meow Warriors as the best NFT game in metaverse
          </Typography>

          <Grid
            container
            spacing={1}
            sx={{
              alignContent: 'center',
              alignItems: 'center',
              pb: 15
            }}>

            <Grid item lg={3} md={3} sm={6} xs={12}>
              <MyCardItem title='Advisor' name='Minh Do' image={IMGA1} />
            </Grid>

            <Grid item lg={3} md={3} sm={6} xs={12}>
              <MyCardItem title='Game Designer' name='Reishi' image={IMGA2} />
            </Grid>

            <Grid item lg={3} md={3} sm={6} xs={12}>
              <MyCardItem title='Lead 2D Artist' name='Tung Monster' image={IMGA3} />
            </Grid>

            <Grid item lg={3} md={3} sm={6} xs={12}>
              <MyCardItem title='Lead Game Developer' name='Minh Huynh' image={IMGA4} />
            </Grid>
          </Grid>
        </Container>

        <Box
          component="img"
          src={`${Decor1}`}
          sx={{
            position: 'absolute',
            right: `0px`,
            top: {
              xs: '-100px',
              sm: '-150px',
              md: '-150px',
              lg: '-150px',
            },
            width: {
              xs: `25vw`,
              sm: `25vw`,
              md: `30vw`,
              lg: `30vw`,
            },
            maxWidth: `230px`,
            zIndex: `999`,
          }} />

        <Box
          component="img"
          src={`${Decor2}`}
          sx={{
            position: 'absolute',
            left: `0px`,
            bottom: {
              xs: '15px',
              sm: '15px',
              md: '15px',
              lg: '15px',
            },
            width: {
              xs: `20vw`,
              sm: `20vw`,
              md: `20vw`,
              lg: `20vw`,
            },
            maxWidth: `200px`,
          }} />

        <Box
          component="img"
          src={`${Decor2}`}
          sx={{
            position: 'absolute',
            right: `0px`,
            bottom: {
              xs: '15px',
              sm: '15px',
              md: '15px',
              lg: '15px',
            },
            width: {
              xs: `20vw`,
              sm: `20vw`,
              md: `20vw`,
              lg: `20vw`,
            },
            maxWidth: `200px`,
            transform: `scaleX(-1)`,
          }} />
      </Box>
    </>
  );
}