import React from 'react';
import { Box, Typography } from '@mui/material';

import ribbonImg from './ribbon.png'; // Adjust the path to your ribbon image

const HeaderWithRibbon = (props: {
    text: string
}) => {
    return (
        <Box sx={{
            position: 'relative',
            pointerEvents: 'none',
            userSelect: 'none',
            maxWidth: `100%`
        }}>
            <Box
                sx={{
                    background: `url(${ribbonImg}) no-repeat center center`,
                    backgroundSize: `contain`,
                    display: `inline-flex`,
                    justifyContent: `center`,
                    minWidth: {
                        xs: `18em`,
                        sm: `20em`,
                        md: `26em`,
                        lg: `26em`
                    },
                }}
            >
                <Typography sx={{
                    background: `linear-gradient(#FFE197, #FFAD51)`,
                    backgroundClip: `text`,
                    WebkitTextFillColor: `transparent`,
                    whiteSpace: 'nowrap',
                    fontWeight: '1000',
                    WebkitTextStroke: '2px #000',
                    color: '#F4F07A',
                    fontSize: {
                        xs: `1.5em`,
                        sm: `2em`,
                        md: `2.5em`,
                        lg: `2.5em`
                    },
                    padding: {
                        xs: `1em 2em 2em 2em`,
                        sm: `1em 2em 2em 2em`,
                        md: `1em 2em 2em 2em`,
                        lg: `1em 2em 2em 2em`
                    }
                }}
                >
                    {props.text ?? 'Untitled'}
                </Typography>
            </Box>
        </Box >
    );
};

export default HeaderWithRibbon;