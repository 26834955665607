import * as React from 'react';
import { alpha, styled, useMediaQuery, useTheme } from '@mui/material';
import {
  Box,
  Button,
  IconButton,
  Container,
  Paper,
  Link,
  Stack,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';

import HeaderWithRibbon from '../components/HeaderWithRibbon';

import Decor1 from './smeo.png';

import './index.css';
import './timeline-md.css';
import './timeline-sm.css';
import './timeline-xs.css';

const timelineData = [
  {
    text: 'Launch Phase',
    // date: 'February 25 2019',
    category: {
      tag: 'Q1 2024',
      color: '#656083'
    },
    link: {
      url: '#',
      text: <>
        <ol>
          <li>Launch Meow Meow Warriors on Android & iOS.</li>
          <li>Implement PLAY-TO-EARN AIRDROP model.</li>
        </ol>
      </>
    }
  },
  {
    current: true,
    text: 'Token Expansion',
    // date: 'February 25 2019',
    category: {
      tag: 'Q2 2024',
      color: '#656083'
    },
    link: {
      url: '#',
      text: <>
        <ol>
          <li>List project's token on major crytocurrency exchanges.</li>
          <li>Conduct token airdrop for early user and contributors.</li>
        </ol>
      </>
    }
  },
  {
    text: 'Platform Enhancement',
    // date: 'February 25 2019',
    category: {
      tag: 'Q3 2024',
      color: '#656083'
    },
    link: {
      url: '#',
      text: <>
        <ol>
          <li>Introduce Game-Fi Platform Economy with token staking.</li>
          <li>Enable token staking for new game NFTs and airdrops.</li>
          <li>Introduce revenue sharing from new game launches.</li>
        </ol>
      </>
    }
  },
  {
    text: 'Innovation and Expansion',
    // date: 'February 25 2019',
    category: {
      tag: 'Q4 2024',
      color: '#656083'
    },
    link: {
      url: '#',
      text: <>
        <ol>
          <li>Develop and launch AI-Powered Technology.</li>
          <li>Attach traditional users with innovative companionship solotions.</li>
        </ol>
      </>
    }
  },
]

const TimelineItem = ({ data }: { data: any }) => (
  <div className={`timeline-item ${data.current ? `selected` : ``}`}>
    <div className="timeline-item-content">
      <span className="tag" style={{ background: data.category.color }}>
        {data.category.tag}
      </span>
      <time>{data.date}</time>
      <p>{data.text}</p>
      {data.link && (
        <a
          href={data.link.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {data.link.text}
        </a>
      )}
      <span className="timeline-item-node" />
    </div>
  </div>
);

function Timeline({ }) {
  return (<>
    {
      timelineData.length > 0 && (
        <div className="timeline-container">
          <div className="timeline-items">
            {timelineData.map((data, idx) => (
              <TimelineItem key={idx} data={data} />
            ))}
          </div>
        </div>
      )
    }
  </>);
}

export default function SectionRoadMap() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Box
        className="section-main"
        id="roadmap"
        sx={(theme) => ({

        })}
      >
        <Container
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: { xs: 0, sm: 6 },
          }}
        >
          <HeaderWithRibbon text={'Roadmap'} />

          <Timeline />

        </Container>
        <Box
          component="img"
          src={`${Decor1}`}
          sx={{
            position: 'absolute',
            right: `0px`,
            top: {
              xs: '-100px',
              sm: '-150px',
              md: '-150px',
              lg: '-150px',
            },
            width: {
              xs: `25vw`,
              sm: `25vw`,
              md: `30vw`,
              lg: `30vw`,
            },
            maxWidth: `180px`,
            zIndex: `999`,
          }} />
      </Box>
    </>
  );
}