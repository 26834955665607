import * as React from 'react';
import { alpha, styled } from '@mui/material';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Container,
  Link,
  Grid,
  Stack,
  Typography,
} from '@mui/material';

import './index.css';

import HeaderWithRibbon from '../components/HeaderWithRibbon';

import IMGCard1 from './images/raid1.png';
import IMGCard2 from './images/raid2.png';
import IMGCard3 from './images/raid3.png';

const MyCardItem = ({ title, text, image }: { title: string, text: string, image: string }) => {
  return (<>
    <Box className="feature-card">
      <Box className="feature-card-image">
        <img src={`${image}`} alt="Circle Masked Image" />
      </Box>

      <Box className="feature-card-header">

        <Box className="feature-card-header-title">
          <Typography component="h3">
            {title}
          </Typography>
        </Box>

        <Box className="feature-card-header-content" component="h5">
          {text}
        </Box>
      </Box>
    </Box>
  </>);
}

export default function SectionFeatures() {
  return (
    <>
      <Box
        className="section-main"
        id="features"
        sx={(theme) => ({
          pt: {
            xs: 5,
            sm: 5,
            md: 1,
            lg: 5,
          },
        })}
      >
        <Container
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <HeaderWithRibbon text={'Game Features'} />

          <Grid
            container
            spacing={1}
            sx={{
              alignContent: 'center',
              alignItems: 'center',
              pb: 15
            }}>

            <Grid item lg={4} md={4} sm={6} xs={12}>
              <MyCardItem
                title='PvE'
                text='Deepen connections with each warrior to unlock their full potential'
                image={IMGCard1}
              />
            </Grid>

            <Grid item lg={4} md={4} sm={6} xs={12}>
              <MyCardItem
                title='PvP'
                text='Deepen connections with each warrior to unlock their full potential'
                image={IMGCard2}
              />
            </Grid>

            <Grid item lg={4} md={4} sm={6} xs={12}>
              <MyCardItem
                title='Warrior Bonding'
                text='Deepen connections with each warrior to unlock their full potential'
                image={IMGCard3}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}