import type { } from '@mui/material/themeCssVarsAugmentation';
import { ThemeOptions, alpha, duration } from '@mui/material/styles';
import {
  amber,
  blue,
  blueGrey,
  brown,
  common,
  cyan,
  deepOrange,
  deepPurple,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from '@mui/material/colors';
import { PaletteMode } from '@mui/material';
import './theme.css';
import { warning } from 'framer-motion';

const primary = red;

const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    palette: {
      primary: {
        main: brown[500],
        contrastText: '#fff',
      },
      secondary: {
        main: lime[500],
        contrastText: '#fff',
      },
      error: {},
      warning: {},
      success: {},
      divider: mode === 'dark' ? alpha(grey[600], 0.3) : alpha(grey[300], 0.5),

      text: {
        primary: 'rgba(0,0,0,0.87)',
        secondary: 'rgba(0,0,0,0.6)',
        disabled: 'rgba(0,0,0,0.38)',
      },
    },
  },
  typography: {
    // fontFamily: ['"Press Start 2P", "Roboto", "Helvetica", "Arial", "sans-serif"'].join(','),
    fontFamily: ['"REM", "Roboto", "Helvetica", "Arial", "sans-serif"'].join(','),
    h1: {
      fontSize: 60,
      fontWeight: 400,
      lineHeight: 78 / 70,
      letterSpacing: -0.2,
    },
    h2: {
      fontSize: 48,
      fontWeight: 400,
      lineHeight: 1.2,
    },
    h3: {
      fontSize: 42,
      lineHeight: 1.2,
    },
    h4: {
      fontSize: 28,
      fontWeight: 400,
      lineHeight: 1.5,
    },
    h5: {
      fontSize: 20,
      fontWeight: 400,
    },
    h6: {
      fontSize: 18,
    },
    subtitle1: {
      fontSize: 20,
    },
    subtitle2: {
      fontSize: 18,
    },
    body1: {
      fontWeight: 400,
      fontSize: 19,
    },
    body2: {
      fontWeight: 400,
      fontSize: 18,
    },
    caption: {
      fontWeight: 400,
      fontSize: 15,
    },
  },
  transition: {
    // easing: {
    //   easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
    // },
    // duration: {},
  }
});

export default function MewtopiaTheme(mode: PaletteMode): ThemeOptions {
  return {
    ...getDesignTokens(mode),
    components: {
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: ({ theme }) => ({
            borderRadius: '10px',
            boxShadow: `0 4px 16px ${alpha(grey[400], 0.2)}`,
            '& .Mui-selected': {
              color: primary[500],
            },
            ...(theme.palette.mode === 'dark' && {
              '& .Mui-selected': {
                color: '#fff',
              },
              boxShadow: `0 4px 16px ${alpha(primary[700], 0.5)}`,
            }),
          }),
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: ({ theme }) => ({
            padding: '12px 16px',
            textTransform: 'none',
            borderRadius: '10px',
            fontWeight: 500,
            ...(theme.palette.mode === 'dark' && {
              color: grey[400],
              boxShadow: '0 4px 16px rgba(0, 0, 0, 0.5)',
              '&.Mui-selected': { color: primary[300] },
            }),
          }),
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableTouchRipple: true,
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            boxSizing: 'border-box',
            transition: 'all 100ms ease-in',
            '&:focus-visible': {
              outline: `3px solid ${alpha(primary[500], 0.5)}`,
              outlineOffset: '2px',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: ({ theme, ownerState }) => ({
            fontSize: 16,
            boxSizing: 'border-box',
            boxShadow: 'none',
            borderRadius: '0px',
            textTransform: 'none',
            '&:active': {
              transform: 'scale(0.98)',
            },
            '&:hover': {
              transform: 'translateY(-3px);',
            },
            ...(ownerState.size === 'small' && {
              maxHeight: '32px',
            }),
            ...(ownerState.size === 'medium' && {
              height: '40px',
            }),
          }),
        },
      },
    },
  };
}