import * as React from 'react';
import { PaletteMode } from '@mui/material';
import { styled } from '@mui/material'
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';

import IMGLogo from './mmw_logo_long.png';

const headerItemSx = {
  fontWeight: `900`,
  fontSize: `0.95rem`,
};

function AppAppBar() {
  const [opacity, setOpacity] = React.useState(0.0);
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const scrollToSection = (sectionId: string) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
      setOpen(false);
    }
  };

  const handleScroll = () => {
    const currentScroll = window.scrollY;
    setOpacity(currentScroll > 200 ? 1 : 0);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <AppBar
        position="fixed"
        sx={(theme) => ({
          backgroundImage: 'none',
          mt: 0,
          // transition: 'all 0.5s ease',
          bgcolor: theme.palette.mode === 'light' ? `rgba(255, 255, 255, ${opacity})` : `rgba(0, 0, 0, ${opacity})`,
          boxShadow: opacity === 0 ? 0 : null,
        })}
      >
        <Toolbar
          variant="regular"
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexShrink: 0,
            borderRadius: '0px',
            maxHeight: 40,
            border: '1px solid',
            borderColor: 'divider',
          })}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              ml: '-18px',
              px: 0,
            }}
          >
            <Box
              onClick={() => scrollToSection('features')}
              component="img"
              className='mewtopia-logo'
              src={`${IMGLogo}`}
              sx={{
                cursor: `pointer`,
                pointerEvents: `all`,
                // transition: 'all 0.48s ease',
                display: opacity === 0 ? `none` : `block`,
                margin: {
                  xs: `0px 30px 0px 0px`,
                  sm: `0px 20px 0px 0px`,
                  md: `0px 16px 0px 0px`,
                  lg: `0px 15px 0px 0px`,
                },
                p: 1,
                maxHeight: opacity === 0 ?
                  {
                    xs: `70px`,
                    sm: `70px`,
                    md: `85px`,
                    lg: `95px`,
                  }
                  :
                  {
                    xs: `45px`,
                    sm: `45px`,
                    md: `60px`,
                    lg: `65px`,
                  },
              }}
            />
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            </Box>
          </Box>

          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              gap: 0.5,
              alignItems: 'center',
            }}
          >

            <MenuItem
              onClick={() => scrollToSection('roadmap')}
              sx={{ py: '6px', px: '12px' }}
            >
              <Typography
                variant="button"
                color={opacity === 0 ? "main.secondary" : "text.primary"}
                sx={{
                  ...headerItemSx
                }}>
                Roadmap
              </Typography>
            </MenuItem>

            <MenuItem
              onClick={() => scrollToSection('partners')}
              sx={{ py: '6px', px: '12px' }}
            >
              <Typography
                variant="button"
                color={opacity === 0 ? "main.secondary" : "text.primary"}
                sx={{
                  ...headerItemSx
                }}>
                Partners
              </Typography>
            </MenuItem>

            <MenuItem
              onClick={() => scrollToSection('team')}
              sx={{ py: '6px', px: '12px' }}
            >
              <Typography
                variant="button"
                color={opacity === 0 ? "main.secondary" : "text.primary"}
                sx={{
                  ...headerItemSx
                }}>
                Team
              </Typography>
            </MenuItem>

            <MenuItem
              href='https://docs.mewtopia.game'
              target='_blank'
              component='a'
              sx={{ py: '6px', px: '12px' }}
            >
              <Typography
                variant="button"
                color={opacity === 0 ? "main.secondary" : "text.primary"}
                sx={{
                  ...headerItemSx
                }}>
                Docs
              </Typography>
            </MenuItem>

            {/* <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} /> */}

            <Button
              color="secondary"
              variant="contained"
              size="small"
              component="a"
              href='https://t.me/mewtopia'
              target='_blank'
            >
              Play Now
            </Button>
          </Box>

          <Box sx={{ display: { sm: '', md: 'none' } }}>
            <Button
              variant="text"
              color="primary"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{ minWidth: '30px', p: '4px' }}
            >
              <MenuIcon />
            </Button>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
              <Box
                sx={{
                  minWidth: '60dvw',
                  p: 2,
                  backgroundColor: 'background.paper',
                  flexGrow: 1,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'end',
                    flexGrow: 1,
                  }}
                >
                  {/* <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} /> */}
                </Box>
                <MenuItem onClick={() => scrollToSection('roadmap')}>
                  Roadmap
                </MenuItem>
                <MenuItem onClick={() => scrollToSection('partners')}>
                  Partners
                </MenuItem>
                <MenuItem onClick={() => scrollToSection('team')}>
                  Team
                </MenuItem>
                <MenuItem href='https://docs.mewtopia.game' target='_blank'>
                  Docs
                </MenuItem>
                {/* <MenuItem onClick={() => scrollToSection('faq')}>FAQ</MenuItem> */}
                <Divider />
                <MenuItem>
                  <Button
                    color="primary"
                    variant="contained"
                    component="a"
                    href="https://t.me/mewtopia"
                    target="_blank"
                    sx={{ width: '100%' }}
                  >
                    PLAY NOW
                  </Button>
                </MenuItem>
              </Box>
            </Drawer>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default AppAppBar;
